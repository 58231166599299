import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Web3 from "web3";
import { FixedSizeList as List } from "react-window";
import "./App.css";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import xdcLogo from './xdclogoimage.png';
import favicon from "./favicon.ico";
import ogImage from './ogImage.png';
import xdckillingethereum from './xdckillingethereum.jpg';


const XDC_ADDRESS = "0x20501c8ead2f114730bb2c440eb45032618cf305"; // Replace with your XDC address
let FROM_BLOCK; // Declare as a global variable
const QUEUE_SIZE = 1000; // Set a maximum number of transactions to store in each queue
const FLASH_DURATION = 500; // Duration of the flash animation in milliseconds
// const WSS_PROVIDER = "https://rpc.xinfin.network";
// const HTTP_PROVIDER = "https://rpc.xinfin.network";
const HTTP_PROVIDER = "wss://ews.xinfin.network";
let realBalance = -1;
let first_time_getting_balance = true;


function App() {
  const [balance, setBalance] = useState(0);
  const [transactionsAbove1000, setTransactionsAbove1000] = useState([]);
  const [transactionsAbove100, setTransactionsAbove100] = useState([]);
  const [transactionsAbove10, setTransactionsAbove10] = useState([]);
  const [transactionsBelow10, setTransactionsBelow10] = useState([]);
  const above1000ListRef = useRef();
  const above100ListRef = useRef();
  const above10ListRef = useRef();
  const below10ListRef = useRef();

  const [flashBackground, setFlashBackground] = useState(false); // State variable to trigger flash animation


  const [openSnackbar, setOpenSnackbar] = useState(false);


  const xdcAddress = "xdc" + XDC_ADDRESS.slice(2);

  // XDC_ADDRESS = xdcAddress;

  // const realBalance = 0;


  const copyToClipboard = async () => {
    // alert("lol");
    try {
      await navigator.clipboard.writeText(xdcAddress);
      // setIsCopied(true);
      setOpenSnackbar(true);

    } catch (error) {
      console.error("Failed to copy to clipboard: ", error);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };


  const setHasNewTransactionsAbove1000 = (hasNew) => {
    above1000ListRef.current.hasNew = hasNew;
  };
  
  const setHasNewTransactionsAbove100 = (hasNew) => {
    above100ListRef.current.hasNew = hasNew;
  };
  
  const setHasNewTransactionsAbove10 = (hasNew) => {
    above10ListRef.current.hasNew = hasNew;
  };
  
  const setHasNewTransactionsBelow10 = (hasNew) => {
    below10ListRef.current.hasNew = hasNew;
  };

  const options = {
    timeout: 60000, // 10 seconds
  };

  // const web3 = new Web3(new Web3.providers.WebsocketProvider(WSS_PROVIDER));
  // const web3 = new Web3(new Web3.providers.HttpProvider(HTTP_PROVIDER));
    const web3 = new Web3(new Web3.providers.WebsocketProvider(HTTP_PROVIDER, options));


    // getBalance();

//   useEffect(() => {
//     const interval = setInterval(() => {
//       // Fetch latest incoming transactions
//       getIncomingTransactions();
//     }, 5000);

//     // Get initial balance and incoming transactions
//     getBalance();
//     getIncomingTransactions();

//     return () => clearInterval(interval);
//   }, []);




//UNCOMMENT BELOW!
  // useEffect(() => {
  //   let timeoutId;
  
  //   const fetchTransactions = async () => {
  //     // Fetch latest incoming transactions

  //     await getBalance();
  //     // await getIncomingTransactions();


      

  //     // Get initial balance and incoming transactions
        
  //     // Set a new timeout with a random time interval
  //     //randomized between 5000 to 10000 *expoential backoff*
  //     // timeoutId = setTimeout(fetchTransactions, Math.floor(Math.random() * 5000) + 5000);
  //     //between 1 minute and 2 minutes
  //     timeoutId = setTimeout(fetchTransactions, Math.floor(Math.random() * 60000) + 60000);
  //   };

  //   //initial
  //   // getBalance();
  //   fetchTransactions();
  
  //   return () => clearTimeout(timeoutId);
  // }, []);

  
  var targetAddress = XDC_ADDRESS;

  const handleNewBlock = (error, result) => {
    // console.log("hi there");
    if (error) {
      console.error('Error:', error);
      return;
    }
  
    // Get the block number and hash
    const blockNumber = result.number;
    const blockHash = result.hash;
    //const hashMap = new Map();
  
    // Get the block details
    web3.eth.getBlock(blockHash, true, (error, result) => {
      if (error) {
        console.error('Error:', error);
        return;
      }

      // var hashMap = new Map();
  
      // Check each transaction in the block
      result.transactions.forEach((tx) => {
        // if (hashMap.has(tx) && tx.to && tx.to.toLowerCase() === targetAddress.toLowerCase()) {
        if (tx.to && tx.to.toLowerCase() === targetAddress.toLowerCase()) {

          // console.log(`Found transaction to ${targetAddress} in block ${blockNumber}:`);
          // console.log(`  Transaction ID: ${tx.hash}`);
          // console.log(`  From address: ${tx.from}`);
          // console.log(`  Amount sent: ${web3.utils.fromWei(tx.value, 'ether')} XDC`);

          web3.eth.getBalance(XDC_ADDRESS).then(balance => {
            const balanceXDC = web3.utils.fromWei(balance, "ether");
        
            var different = (balanceXDC !== realBalance);
            setBalance(balanceXDC);
            realBalance = balanceXDC;
        
            if (different) {
              onBalanceFunction(balance);
            }
            
          })

          // const balanceXDC = web3.utils.fromWei(tx.value, "ether");

          // var different = (balanceXDC !== realBalance);
          // setBalance(balanceXDC);
          // realBalance = balanceXDC;
      
          // if (different) {
          //   onBalanceFunction(tx.value);
          // } else {
          //   // alert("dog");
          // }

          // hashMap.set(tx, tx);
        }
        
      });

      // hashMap.clear();
    });
  }
  
  const subscription = web3.eth.subscribe('newBlockHeaders', handleNewBlock);

  const onBalanceFunction = function() {
    try {
    // const balanceWei = await web3.eth.getBalance(xdcAddress);
    // const balanceXDC = web3.utils.fromWei(balanceWei, "ether");

    // console.log(balanceXDC);
    // alert("succkkkbalance: " + balance);

    // Check if balance has changed
    // if (balanceXDC !== balance) {

    // console.log(balanceXDC);
    // console.log(realBalance);


    // if ((balanceXDC !== realBalance)) {
    //   alert("balance: " + realBalance);
    //   alert("balanceXDC " + balanceXDC);

      // setBalance(balanceXDC);


      // realBalance = balanceXDC;

      if(first_time_getting_balance) {
        first_time_getting_balance = false;
      } else {
        
        // Flash background rainbow colors for a few seconds
        // document.body.style.background = "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)";
        const rootDiv = document.getElementById("root");
        const firstChildDiv = rootDiv.firstElementChild;
        const firstChildDivOfFirstChildDiv = firstChildDiv.firstElementChild;
        // const originalBackgroundColor = firstChildDiv.style.backgroundColor;
        const originalBackgroundColor = "white";
        // alert(originalBackgroundColor);
        const colors = ["red", "orange", "yellow", "green", "blue", "indigo", "violet"];
        let index = 0;

        firstChildDiv.style.background = colors[index];
        firstChildDivOfFirstChildDiv.style.background = colors[index];


        // Change the background color to the next color in the sequence every 500 milliseconds (0.5 seconds)
        const intervalId = setInterval(() => {
          index++;
          if (index >= colors.length) {
            index = 0;
          }
          firstChildDiv.style.background = colors[index];
          firstChildDivOfFirstChildDiv.style.background = colors[index];
        }, 500);

        // Stop changing the background color after 3000 milliseconds (3 seconds)
        setTimeout(() => {
          clearInterval(intervalId);
          firstChildDiv.style.background = originalBackgroundColor;
          firstChildDivOfFirstChildDiv.style.background = originalBackgroundColor;
          // alert("LMAO");
          // setBalance(balance);
          // alert(realBalance);
        }, 3000);
              
      }
      
      // // firstChildDiv.style.background = "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)";
      // setTimeout(() => {
      //   // document.body.style.background = "";
      //   firstChildDiv.style.background = originalBackgroundColor;

      // }, 3000);

      // Update balance state
      // realBalance = balanceXDC;

      // setBalance(balanceXDC);
      
      // this.forceUpdate();

      // setBalance(50);

    // }
  } catch (error) {
    //console.log(error);
  }
};


  // web3.eth.subscribe('newBlockHeaders', (error, result) => {
  //   if (error) {
  //     console.error(error);
  //   } else {
  //     console.log(result);

  //     // const balance = web3.eth.getBalance(XDC_ADDRESS);
  //     // console.log(balance);
  //     // getBalance();

  //     // web3.eth.getBalance(XDC_ADDRESS)
  //     // .then(balance => console.log(`The balance of ${XDC_ADDRESS} is ${balance}`));
  //     // web3.eth.getBalance(XDC_ADDRESS)
  //     // .then(balance => onBalanceFunction(balance));
  //   }
  // });

  web3.eth.subscribe('connected', () => web3.eth.getBalance(XDC_ADDRESS).then(balance => {
    const balanceXDC = web3.utils.fromWei(balance, "ether");

    var different = (balanceXDC !== realBalance);
    setBalance(balanceXDC);
    realBalance = balanceXDC;

    if (different) {
      onBalanceFunction(balance);
    }
    
  }));
  
  // web3.eth.getBalance(XDC_ADDRESS).then(balance => onBalanceFunction(balance))

//   // Check if the connection is established
// function checkConnection() {
//   web3.eth.net.isListening()
//     .then(listening => {
//       if (listening) {
//         // if (!connectedSubscription) {
//           // Subscribe to the 'connected' event if it hasn't been subscribed to yet
//           // subscribeToConnected();
//           web3.eth.getBalance(XDC_ADDRESS).then(balance => onBalanceFunction(balance));
//         // }
//       } else {
//         // Connection not established yet, wait and check again
//         setTimeout(checkConnection, 1000);
//       }
//     })
//     .catch(e => console.log('Web3 connection error:', e));
// }

// checkConnection();


  // web3.eth.net.isListening()
  // .then(() => {
  //   console.log('Web3 is connected');
  //   // // Get the balance of an Ethereum address
  //   // const address = '0x1234567890123456789012345678901234567890';
  //   // web3.eth.getBalance(address)
  //   //   .then(balance => console.log('Balance of', address, 'is', web3.utils.fromWei(balance)))
  //   //   .catch(e => console.log('Error getting balance:', e));

  //     web3.eth.getBalance(XDC_ADDRESS)
  //     .then(balance => onBalanceFunction(balance));
  // })
  // .catch(e => console.log('Web3 connection error:', e));

  // web3.eth.net.on('connected', () => {
  //   console.log('Web3 connected to node');
  // });


  // web3.eth.net.on('connect', () => {
  //   console.log('Connected to the Ethereum network');
  // });
  
  // web3.eth.net.on('disconnect', () => {
  //   console.log('Disconnected from the Ethereum network');
  //   // unsubscribeFromEvents();
  // });

// wait for web3 to be fully loaded before using the 'on' function
// web3.eth.net.getId((error, result) => {
//   if (error) {
//     console.error(error);
//   } else {
//     // listen for the 'connected' event and log a message when connected
//     web3.eth.net.on('connected', () => {
//       console.log('Web3 connected!');
//     });

//     // listen for the 'error' event and log any errors
//     web3.eth.net.on('error', error => {
//       console.error(`Web3 error: ${error}`);
//     });
//   }
// });


  // useEffect(() => {
  //   // Scroll to top of list when new items are added
  //   // const listRefs = [
  //   //   { ref: above1000ListRef, transactions: transactionsAbove1000 },
  //   //   { ref: above100ListRef, transactions: transactionsAbove100 },
  //   //   { ref: above10ListRef, transactions: transactionsAbove10 },
  //   //   { ref: below10ListRef, transactions: transactionsBelow10 }
  //   // ];

  //   const listRefs = [    
  //       { ref: above1000ListRef, transactions: transactionsAbove1000, hasNew: false },    
  //       { ref: above100ListRef, transactions: transactionsAbove100, hasNew: false },    
  //       { ref: above10ListRef, transactions: transactionsAbove10, hasNew: false },    
  //       { ref: below10ListRef, transactions: transactionsBelow10, hasNew: false }  
  //   ];

  //   listRefs.forEach(({ ref, transactions, hasNew }) => {
  //     const scrollTop = ref.current.scrollTop;
  //     const hasScrolledToTop = scrollTop === 0;
  //   //   const hasNewTransactions = transactions.length > ref.current.props.itemCount;
  //   //   if (hasScrolledToTop && hasNewTransactions) {
  //     if (hasScrolledToTop && hasNew) {
  //       ref.current.scrollToItem(0, 'start');
  //       // Flash the first row to indicate new item added
  //       const firstRow = ref.current.getItemStyle(0);
  //       firstRow.classList.add('flash');
  //       setTimeout(() => {
  //         firstRow.classList.remove('flash');
  //       }, FLASH_DURATION);
  //     }
  //   });

  //   // Reset the "hasNew" flags for each list after scrolling
  //   setHasNewTransactionsAbove1000(false);
  //   setHasNewTransactionsAbove100(false);
  //   setHasNewTransactionsAbove10(false);
  //   setHasNewTransactionsBelow10(false);

  // }, [transactionsAbove1000, transactionsAbove100, transactionsAbove10, transactionsBelow10]);




  // const getBalance = async () => {
  //   try {
  //   //   const web3 = new Web3(Web3.givenProvider);
  //     const balanceWei = await web3.eth.getBalance(XDC_ADDRESS);
  //     const balanceXDC = web3.utils.fromWei(balanceWei, "ether");
  //     setBalance(balanceXDC);
  //   } catch (error) {
  //     //console.log(error);
  //   }
  // };

  //  This code first checks if the new balance (balanceXDC) is different from the current balance (balance). If it is, it sets the background to a rainbow-colored gradient for 3 seconds using document.body.style.background. After 3 seconds, it resets the background to its original color by setting document.body.style.background to an empty string. Finally, it updates the balance state using setBalance(balanceXDC).
  const getBalance = async () => {
    try {
      const balanceWei = await web3.eth.getBalance(xdcAddress);
      const balanceXDC = web3.utils.fromWei(balanceWei, "ether");

      //console.log(balanceXDC);
  
      // Check if balance has changed
      // if (balanceXDC !== balance) {
      if ((balanceXDC !== realBalance)) {
        // alert("balance: " + realBalance);
        // alert("balanceXDC " + balanceXDC);

        // realBalance = balanceXDC;

        if(first_time_getting_balance) {
          first_time_getting_balance = false;
        } else {
          // Flash background rainbow colors for a few seconds
          // document.body.style.background = "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)";
          const rootDiv = document.getElementById("root");
          const firstChildDiv = rootDiv.firstElementChild;
          const firstChildDivOfFirstChildDiv = firstChildDiv.firstElementChild;
          const originalBackgroundColor = firstChildDiv.style.backgroundColor;
          // alert(originalBackgroundColor);
          const colors = ["red", "orange", "yellow", "green", "blue", "indigo", "violet"];
          let index = 0;

          firstChildDiv.style.background = colors[index];
          firstChildDivOfFirstChildDiv.style.background = colors[index];


          // Change the background color to the next color in the sequence every 500 milliseconds (0.5 seconds)
          const intervalId = setInterval(() => {
            index++;
            if (index >= colors.length) {
              index = 0;
            }
            firstChildDiv.style.background = colors[index];
            firstChildDivOfFirstChildDiv.style.background = colors[index];
          }, 500);

          // Stop changing the background color after 3000 milliseconds (3 seconds)
          setTimeout(() => {
            clearInterval(intervalId);
            firstChildDiv.style.background = originalBackgroundColor;
            firstChildDivOfFirstChildDiv.style.background = originalBackgroundColor;
            // alert("LMAO");
            // setBalance(balance);
            // alert(realBalance);
          }, 3000);
                
        }
        
        // // firstChildDiv.style.background = "linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)";
        // setTimeout(() => {
        //   // document.body.style.background = "";
        //   firstChildDiv.style.background = originalBackgroundColor;

        // }, 3000);
  
        // Update balance state
        realBalance = balanceXDC;

        setBalance(balanceXDC);
        // setBalance(50);
      }
    } catch (error) {
      //console.log(error);
    }
  };
  
  
  // getBalance();
  // web3.eth.getBalance(XDC_ADDRESS).then(balance => onBalanceFunction(balance));
  
  
  
  

  // const getIncomingTransactions = async () => {
  //   try {
  //   //   const web3 = new Web3(Web3.givenProvider);
  //     const latestBlockNumber = await web3.eth.getBlockNumber();
  //     if (!FROM_BLOCK) {
  //       FROM_BLOCK = await getLatestTransactionBlockNumber(web3, XDC_ADDRESS);
  //     }
  //     const events = await web3.eth.getPastEvents("transfer", {
  //       filter: {
  //         to: XDC_ADDRESS
  //       },
  //       fromBlock: FROM_BLOCK,
  //       toBlock: latestBlockNumber
  //     });
  //     const transactions = await Promise.all(
  //       events.map(async event => {
  //         const tx = await web3.eth.getTransaction(event.transactionHash);
  //         const value = web3.utils.fromWei(event.returnValues.value, "ether");
  //         const from = tx.from;
  //         const input = tx.input;
  //         const timestamp = tx.timestamp;
  //         return { value, from, input, timestamp};
  //       })
  //     );
  //     setTransactionQueues(transactions);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const getIncomingTransactions = async () => {
    try {
      const latestBlockNumber = await web3.eth.getBlockNumber();
      if (!FROM_BLOCK) {
        FROM_BLOCK = await getLatestTransactionBlockNumber(web3, XDC_ADDRESS);
        //console.log("FIRSTBLOCK: " + FROM_BLOCK);
      }
      //console.log("pre" + FROM_BLOCK);
      //console.log("latestblock: " + latestBlockNumber);
      const options = {
        fromBlock: FROM_BLOCK,
        toBlock: latestBlockNumber,
        address: XDC_ADDRESS,
        topics: [web3.utils.sha3('Transfer(address,address,uint256)')]
      };
      const logs = await web3.eth.getPastLogs(options);
      //console.log(logs);
      const transactions = logs.map(log => {
        const value = web3.utils.fromWei(log.data, 'ether');
        const from = log.topics[1].replace(/^0+/, '');
        const timestamp = new Date(log.timestamp * 1000);
        return { value, from, timestamp };
      });
      //console.log(transactions);
      setTransactionQueues(transactions);
      //console.log("AFTER" + FROM_BLOCK);
    } catch (error) {
      console.log(error);
    }
  };
  // const getIncomingTransactions = async () => {
  //   try {
  //     const latestBlockHeader = await web3.eth.subscribe('newBlockHeaders');
  //     const latestBlock = await web3.eth.getBlock(latestBlockHeader.number);
  //     if (!FROM_BLOCK) {
  //       FROM_BLOCK = await getLatestTransactionBlockNumber(web3, XDC_ADDRESS);
  //     }
  //     const events = latestBlock.transactions.filter(tx => tx.to.toLowerCase() === XDC_ADDRESS.toLowerCase());
  //     const transactions = await Promise.all(
  //       events.map(async event => {
  //         const tx = await web3.eth.getTransaction(event.hash);
  //         const value = web3.utils.fromWei(tx.value, "ether");
  //         const from = tx.from;
  //         const input = tx.input;
  //         const timestamp = latestBlock.timestamp;
  //         return { value, from, input, timestamp };
  //       })
  //     );
  //     setTransactionQueues(transactions);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  
  const setTransactionQueues = (transactions) => {
    const transactionsAbove1000XDC = transactions.filter((tx) => tx.value >= 1000);
    const transactionsAbove100XDC = transactions.filter((tx) => tx.value < 1000 && tx.value >= 100);
    const transactionsAbove10XDC = transactions.filter((tx) => tx.value < 100 && tx.value >= 10);
    const transactionsBelow10XDC = transactions.filter((tx) => tx.value < 10);

    setTransactionsAbove1000((prevTransactions) => {
      const newTransactions = [...prevTransactions, ...transactionsAbove1000XDC];
      setHasNewTransactionsAbove1000(newTransactions.length > prevTransactions.length);
      return newTransactions.slice(-QUEUE_SIZE);
    });

    setTransactionsAbove100((prevTransactions) => {
      const newTransactions = [...prevTransactions, ...transactionsAbove100XDC];
      setHasNewTransactionsAbove100(newTransactions.length > prevTransactions.length);
      return newTransactions.slice(-QUEUE_SIZE);
    });

    setTransactionsAbove10((prevTransactions) => {
      const newTransactions = [...prevTransactions, ...transactionsAbove10XDC];
      setHasNewTransactionsAbove10(newTransactions.length > prevTransactions.length);
      return newTransactions.slice(-QUEUE_SIZE);
    });

    setTransactionsBelow10((prevTransactions) => {
      const newTransactions = [...prevTransactions, ...transactionsBelow10XDC];
      setHasNewTransactionsBelow10(newTransactions.length > prevTransactions.length);
      return newTransactions.slice(-QUEUE_SIZE);
    });
    // Update FROM_BLOCK to the latest block number
    FROM_BLOCK = transactions[0] ? transactions[0].blockNumber + 1 : FROM_BLOCK;
  };

  const getLatestTransactionBlockNumber = async (web3, address) => {
    //console.log("address: " + address);
    //const latestBlockNumber = await web3.eth.getBlockNumber();
    //console.log("LATESTBLOCKNUMBER: ", latestBlockNumber);
    // const options = { fromBlock: 0, toBlock: "latest", address, topics: ["0x000000000000000000000000" + address.slice(2)] };
    const options = { fromBlock: 0, toBlock: "latest", address };
    const result = await web3.eth.getPastLogs(options);
    //console.log("result " + result);
    return result[0] ? result[0].blockNumber : 0;
  };

  const Row = ({ index, style }) => {
    let transactions;
    if (index === 0) {
      transactions = transactionsAbove1000;
    } else if (index === 1) {
      transactions = transactionsAbove100;
    } else if (index === 2) {
      transactions = transactionsAbove10;
    } else {
      transactions = transactionsBelow10;
    }

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const dateString = date.toLocaleString();
        return dateString;
      };

      const formatDateTime = (timestamp) => {
        const date = new Date(timestamp * 1000);
        const dateTimeString = date.toLocaleTimeString();
        return dateTimeString;
      };
    
    return (
        <div style={style}>
            {transactions.map((tx, i) => (
            <div key={i}>
                <p>{`${tx.from} sent ${tx.value} XDC on ${formatDate(tx.timestamp)} ${formatDateTime(tx.timestamp)}`}</p>
                {tx.input && <p>{`Message: ${web3.utils.hexToAscii(tx.input)}`}</p>}
            </div>
            ))}
        </div>
        );

  };

  // return (
  //   <div>
  //     <h1>XDC Address: {XDC_ADDRESS}</h1>
  //     <h2>Current XDC Balance: {balance} XDC</h2>
  //     <h2>Incoming Transactions:</h2>
  //     <h2>Transactions above 1000 XDC:</h2>
  //     <List ref={above1000ListRef} height={400} itemCount={4} itemSize={100} width="100%">
  //       {Row}
  //     </List>
  //     <h2>Transactions above 100 XDC:</h2>
  //     <List ref={above100ListRef} height={400} itemCount={4} itemSize={100} width="100%">
  //       {Row}
  //     </List>
  //     <h2>Transactions above 10 XDC:</h2>
  //     <List ref={above10ListRef} height={400} itemCount={4} itemSize={100} width="100%">
  //       {Row}
  //     </List>
  //     <h2>Transactions above 0 XDC:</h2>
  //     <List ref={below10ListRef} height={400} itemCount={4} itemSize={100} width="100%">
  //       {Row}
  //     </List>
  //   </div>
  // );

  // const xdcAddress = "xdc" + XDC_ADDRESS.slice(2);

  return (
    // <div
    //   style={{
    //     display: "flex",
    //     flexDirection: "column",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     minHeight: "100vh",
    //     backgroundColor: "#f2f2f2",
    //     padding: "20px",
    //   }}
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        minHeight: "100vh",
        backgroundColor: "white",
        padding: "20px",
      }}
    >

<div style={{
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  height: "33%",
  paddingTop: "20px",
  backgroundColor: "white",
}}>

      <Helmet>
        {/* <title>XDC.ME! Donate XDC to Me!</title> */}
        {/* <meta property="og:image" content={`https://xdc.me${ogImage}`} /> */}
        {/* <meta property="title" content="XDC.ME! Donate XDC to Me!" /> */}
        {/* <meta name="description" content="Make me the richest XDC wallet ever! Donate XDC to me today and be part of history!" /> */}
        <link rel="shortcut icon" href={favicon} type="image/x-icon" />
{/* 
        <meta name="keywords" content="xdc.me,xdc me,donate xdc,free xdc,xdc faucet"/>
        <meta name="author" content=""/>
        <meta property="og:image" content="https://i.imgur.com/x9LKqDI.png"/>
        <meta property="og:image:width" content="1127"/>
        <meta property="og:image:height" content="627"/> */}
        {/* <meta name="og:title" property="og:title" content="XDC.ME! Donate XDC to Me!"/> */}
        {/* <meta name="og:description" property="og:description" content="Make me the richest XDC wallet ever! Donate XDC to me today and be part of history!"/> */}
      </Helmet>

      <img src={xdcLogo} alt="XDC logo" style={{ width: "200px", marginBottom: "0px" }} /> 
        
       <h1
        style={{
          fontSize: "54px",
          marginBottom: "0px",
          marginTop: "0px",
          textAlign: "center",
          wordWrap: "break-word",
          wordBreak: "break-word",
        }}
      >
        XDC.ME PLS!
      </h1>

      <div style={{ position: "relative" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0px",
            }}
          >        
            <h1
              style={{
                fontSize: "32px",
                marginRight: "10px",
                wordWrap: "break-word",
                wordBreak: "break-word",
                cursor: "pointer",
              }}
              onClick={copyToClipboard}
            >
              {xdcAddress}
            </h1>
            <button
              style={{
                fontSize: "16px",
                padding: "10px 20px",
                border: "none",
                background: "transparent",
                borderRadius: "5px",
                cursor: "pointer",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
              onClick={copyToClipboard}
            >
              Copy
            </button>

            <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            style={{ position: "absolute", bottom: 0, left: "50%" }}
          >
            <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
              XDC Address copied successfully!
            </Alert>
          </Snackbar>
          

          </div>
        </div>

      <h2
        style={{
          fontSize: "54px",
          marginTop: "0px",
          marginBottom: "0px",
          textAlign: "center",
          wordWrap: "break-word",
          wordBreak: "break-word",
          lineHeight: "1.5",
        }}
      >
        {balance} XDC
      </h2>

      <h1
        style={{
          marginTop: "0px",
          marginBottom: "0px",
          fontSize: "20px",
          textAlign: "center",
          wordWrap: "break-word",
          wordBreak: "break-word",
        }}
      >
        When you donate XDC to me, you donate to a part of history. Let's make me the richest wallet on XDC!
      </h1>

      <br></br>

      <h1
        style={{
          marginTop: "0px",
          marginBottom: "0px",
          fontSize: "30px",
          textAlign: "center",
          wordWrap: "break-word",
          wordBreak: "break-word",
        }}
      >
        XDC will kill Ethereum! XDC is the REAL Ethereum Killer! 😎👍
      </h1>

      <div>
      <img src={xdckillingethereum} alt="XDC killing Ethereum" style={{ width: '100%' }} />
    </div>

      {/* Add the rest of your code here */}
      <style>
        {`
          @media (max-width: 768px) {
            h1 {
              font-size: 32px;
              margin-bottom: 20px;
            }
            h2 {
              font-size: 36px;
              line-height: 1.2;
            }
            h1, h2 {
              text-align: center;
            }
          }
        `}
      </style>
      </div>
    </div>
  );
};

export default App;